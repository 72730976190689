import React from 'react';
import './Cards.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Cards() {
  return (
    <div className='cards'>
      <h1>Bendrijos naujienos</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
  <Card sx={{ 
    minWidth: 225, 
    width: 300, 
    textAlign: 'center',
    background: 'linear-gradient(to bottom, #ffffff, #f0f8ff)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    border: '2px solid #c41e3a',
    borderRadius: '12px'
  }}>
    <CardContent>
      <Typography variant="h4" component="div" sx={{
        color: '#c41e3a',
        fontWeight: 'bold',
        marginBottom: 2,
        fontFamily: '"Playfair Display", serif'
      }}>
        Linksmų Švenčių!
      </Typography>
      
      <Typography variant="body1" sx={{ 
        lineHeight: 1.6, 
        textAlign: 'center',
        color: '#2c5530',
        marginBottom: 2,
        fontFamily: '"Roboto", sans-serif'
      }}>
        Tegul šios šventės atneša į Jūsų namus šilumą, 
        džiaugsmą bei ramybę ir pripildo 
        Jūsų širdis meile ir viltimi.
      </Typography>

      <div style={{
        textAlign: 'center',
        marginTop: 3,
        marginBottom: 2,
        color: '#c41e3a'
      }}>
        <Typography variant="body1" sx={{ 
          fontStyle: 'italic',
          marginBottom: 1
        }}>
          Gražių ir jaukių švenčių Jums ir Jūsų artimiesiems!
        </Typography>
        <Typography variant="body2" sx={{ 
          marginTop: 2,
          color: '#666'
        }}>
          Sodų bendrijos "Piliakalnis" valdyba
        </Typography>
      </div>
    </CardContent>
  </Card>
</ul>                     
        </div>
      </div>
    </div>
  );
}

export default Cards;
